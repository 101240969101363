import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';
import 'bootstrap/dist/css/bootstrap.min.css';

// routing
import Routes from './routes';

// defaultTheme
import themes from './themes';
import { useNavigate } from 'react-router-dom';

// project imports
import NavigationScroll from './layout/NavigationScroll';

// ===========================|| APP ||=========================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    const navigate = useNavigate();

    const AuthCheck = () => {
        const auth = localStorage.getItem('adminLogged');
        if (!auth) {
            navigate('/pages/login/login3');
        }
    };

    useEffect(() => {
        AuthCheck();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <CssBaseline />
                <NavigationScroll>
                    <Routes />
                </NavigationScroll>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
