import React, { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import TreasurerVote from 'views/treasurer/TreasurerVote';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// treasurer
const TreasurerItems = Loadable(lazy(() => import('views/treasurer/TreasurerItems')));
const TreasurerUsers = Loadable(lazy(() => import('views/treasurer/TreasurerUsers')));
const RequestDeposit = Loadable(lazy(() => import('views/treasurer/RequestDeposit')));
const RequestWithdraw = Loadable(lazy(() => import('views/treasurer/RequestWithdraw')));

// add item
const TreasurerAddItem = Loadable(lazy(() => import('views/treasurer/TreasurerAddItem')));

// detail
const TreasurerItemDetail = Loadable(lazy(() => import('views/treasurer/TreasurerItemDetail')));

// Book
const TreasurerBook = Loadable(lazy(() => import('views/treasurer/TreasurerBook')));
const TreasurerBookDetail = Loadable(lazy(() => import('views/treasurer/TreasurerBookDetail')));

// soldout
const TreasurerSoldOut = Loadable(lazy(() => import('views/treasurer/TreasurerSoldOut')));
const TreasurerSoldOutAddItem = Loadable(lazy(() => import('views/treasurer/TreasurerSoldOutAddItem')));
const TreasurerSoldOutDetail = Loadable(lazy(() => import('views/treasurer/TreasurerSoldOutDetail')));

// orderbooks
const TreasurerOrderbooks = Loadable(lazy(() => import('views/treasurer/TreasurerOrderbooks')));

// treasurer App Push
const TreasurerAppPush = Loadable(lazy(() => import('views/treasurer/TreasurerAppPush')));

// club
// const TreasurerClub = Loadable(lazy(() => import('views/treasurer/TreasurerClub')));
// const TreasurerClubAdd = Loadable(lazy(() => import('views/treasurer/TreasurerClubAdd')));
// const TreasurerClubDetail = Loadable(lazy(() => import('views/treasurer/TreasurerClubDetail')));

// banner
const TreasurerBanner = Loadable(lazy(() => import('views/treasurer/TreasurerBanner')));
const TreasurerBannerAdd = Loadable(lazy(() => import('views/treasurer/TreasurerBannerAdd')));
const TreasurerBannerDetail = Loadable(lazy(() => import('views/treasurer/TreasurerBannerDetail')));

// notice
const TreasurerNotice = Loadable(lazy(() => import('views/treasurer/TreasurerNotice')));
const TreasurerNoticeAdd = Loadable(lazy(() => import('views/treasurer/TreasurerNoticeAdd')));
const TreasurerNoticeDetail = Loadable(lazy(() => import('views/treasurer/TreasurerNoticeDetail')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ===========================|| MAIN ROUTING ||=========================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/dashboard/default',
            element: <DashboardDefault />
        },
        {
            path: '/utils/util-typography',
            element: <UtilsTypography />
        },
        {
            path: '/utils/util-color',
            element: <UtilsColor />
        },
        {
            path: '/utils/util-shadow',
            element: <UtilsShadow />
        },
        {
            path: '/treasurer/items',
            element: <TreasurerItems />
        },
        {
            path: '/treasurer/users',
            element: <TreasurerUsers />
        },
        {
            path: '/treasurer/request-deposit',
            element: <RequestDeposit />
        },
        {
            path: '/treasurer/request-withdraw',
            element: <RequestWithdraw />
        },
        {
            path: '/treasurer/add/item',
            element: <TreasurerAddItem />
        },
        {
            path: '/treasurer/item/detail/:itemId',
            element: <TreasurerItemDetail />
        },
        {
            path: '/treasurer/book',
            element: <TreasurerBook />
        },
        {
            path: '/treasurer/book/detail/:itemId',
            element: <TreasurerBookDetail />
        },
        {
            path: '/treasurer/soldout',
            element: <TreasurerSoldOut />
        },
        {
            path: '/treasurer/soldout/add/item',
            element: <TreasurerSoldOutAddItem />
        },
        {
            path: '/treasurer/soldout/detail/:itemId',
            element: <TreasurerSoldOutDetail />
        },
        {
            path: '/treasurer/vote',
            element: <TreasurerVote />
        },
        {
            path: '/treasurer/orderbooks',
            element: <TreasurerOrderbooks />
        },
        {
            path: '/treasurer/app',
            element: <TreasurerAppPush />
        },
        // {
        //     path: '/treasurer/club',
        //     element: <TreasurerClub />
        // },
        // {
        //     path: '/treasurer/add/club',
        //     element: <TreasurerClubAdd />
        // },
        // {
        //     path: '/treasurer/club/detail/:id',
        //     element: <TreasurerClubDetail />
        // },
        {
            path: '/treasurer/banner',
            element: <TreasurerBanner />
        },
        {
            path: '/treasurer/add/banner',
            element: <TreasurerBannerAdd />
        },
        {
            path: '/treasurer/banner/detail/:id',
            element: <TreasurerBannerDetail />
        },
        {
            path: '/treasurer/notice',
            element: <TreasurerNotice />
        },
        {
            path: '/treasurer/add/notice',
            element: <TreasurerNoticeAdd />
        },
        {
            path: '/treasurer/notice/detail/:id',
            element: <TreasurerNoticeDetail />
        },
        // {
        //     path: '/treasurer/add/club',
        //     element: <TreasurerClubAdd />
        // },
        // {
        //     path: '/treasurer/club/detail/:id',
        //     element: <TreasurerClubDetail />
        // },
        {
            path: '/sample-page',
            element: <SamplePage />
        }
    ]
};

export default MainRoutes;
