export default {
    // HOST: `http://localhost:3001/api/admin/dashboard`
    // HOST: `http://192.168.1.36:3001/api/admin/dashboard`
    // HOST: `http://localhost:8081/api/admin/dashboard`
    // HOST: 'https://howmuch.zikto.com/api/admin/dashboard' // 얼마야 release
    HOST: 'https://treasurer.zikto.com/api/admin/dashboard'
    // HOST: "http://dev.zikto.com:3001/api/admin/dashboard" // 얼마야 Dev
    // HOST: 'https://howmuch-alpha.zikto.com' // LOCAL/
    // HOST: 'http://localhost:8082/api/admin/dashboard' // 얼마야 Dev
};
