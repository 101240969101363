// assets
import {
    IconBrandFramer,
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconLayoutGridAdd,
    IconAnchor,
    IconUser,
    IconPackage,
    IconLogin,
    IconLogout,
    IconBook,
    IconBatteryCharging,
    IconNotes,
    IconFileCheck,
    IconPhoto,
    IconPinned
} from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconBrandFramer,
    IconLayoutGridAdd,
    IconAnchor,
    IconUser,
    IconPackage,
    IconLogin,
    IconLogout,
    IconBook,
    IconBatteryCharging,
    IconNotes,
    IconFileCheck,
    IconPhoto,
    IconPinned
};

// ===========================|| UTILITIES MENU ITEMS ||=========================== //

const utilities = {
    id: 'treasurer',
    title: 'Treasurer',
    type: 'group',
    children: [
        {
            id: 'items',
            title: 'Treasurer Items',
            type: 'item',
            url: '/treasurer/items',
            icon: icons.IconPackage,
            breadcrumbs: false
        },
        {
            id: 'users',
            title: 'Users',
            type: 'item',
            url: '/treasurer/users',
            icon: icons.IconUser,
            breadcrumbs: false
        },
        {
            id: 'deposit',
            title: 'Request Deposit',
            type: 'item',
            url: '/treasurer/request-deposit',
            icon: icons.IconLogin,
            breadcrumbs: false
        },
        {
            id: 'withdraw',
            title: 'Request Withdraw',
            type: 'item',
            url: '/treasurer/request-withdraw',
            icon: icons.IconLogout,
            breadcrumbs: false
        },
        {
            id: 'book',
            title: 'Treasurer Book',
            type: 'item',
            url: '/treasurer/book',
            icon: icons.IconBook,
            breadcrumbs: false
        },
        {
            id: 'soldout',
            title: 'Treasurer SoldOut',
            type: 'item',
            url: '/treasurer/soldout',
            icon: icons.IconBatteryCharging,
            breadcrumbs: false
        },
        {
            id: 'vote',
            title: 'Treasurer Vote',
            type: 'item',
            url: '/treasurer/vote',
            icon: icons.IconFileCheck,
            breadcrumbs: false
        },
        {
            id: 'orderbooks',
            title: 'Treasurer Orderbooks',
            type: 'item',
            url: '/treasurer/orderbooks',
            icon: icons.IconNotes,
            breadcrumbs: false
        },
        {
            id: 'appPush',
            title: 'Treasurer App Push',
            type: 'item',
            url: '/treasurer/app',
            icon: icons.IconWindmill,
            breadcrumbs: false
        },
        {
            id: 'banner',
            title: 'Treasurer Banner',
            type: 'item',
            url: '/treasurer/banner',
            icon: icons.IconPhoto,
            breadcrumbs: false
        },
        {
            id: 'notice',
            title: 'Treasurer Notice',
            type: 'item',
            url: '/treasurer/notice',
            icon: icons.IconPinned,
            breadcrumbs: false
        }
        // {
        //     id: 'club',
        //     title: 'Treasurer Club',
        //     type: 'item',
        //     url: '/treasurer/club',
        //     icon: icons.IconBrandFramer,
        //     breadcrumbs: false
        // }
        // {
        //     id: 'treasurer',
        //     title: 'Treasurer',
        //     type: 'collapse',
        // icon: icons.IconAnchor,
        //     children: [
        //         {
        //             id: 'tabler-icons',
        //             title: 'Request Deposit',
        //             type: 'item',
        //             url: '/treasurer/request-deposit',
        //             breadcrumbs: false
        //         },
        //         {
        //             id: 'material-icons',
        //             title: 'Request Withdraw',
        //             type: 'item',
        //             url: '/treasurer/request-withdraw',
        //             breadcrumbs: false
        //         }
        //     ]
        // }
    ]
};

export default utilities;
