import axios from 'axios';
import CONST from '../const';

const api = axios.create({
    baseURL: CONST.HOST
});

export const setAuth = (token) => {
    api.defaults.headers.Authorization = `Bearer ${token}`;
};

export const loginSet = {
    authCheck: () => api.get('/')
};

export const getBaseUrl = () => CONST.HOST;

// eslint-disable-next-line import/prefer-default-export
export const treasurer = {
    getItems: () => api.get('/v2/treasurer/get/items'),
    getItemDetail: (itemId) => api.get(`/v2/treasurer/get/item/detail/${itemId}`),

    getUsers: () => api.get('/v2/treasurer/get/users'),

    getRequestDeposit: () => api.get('/v2/treasurer/get/request/deposit'),

    confirmRequestDeposit: (requestList) => api.post('/v2/treasurer/confirm/request/list/deposit', { requestList }),

    confirmRequestDepositCancel: (requestList) => api.post('/v2/treasurer/cancel/request/list/deposit', { requestList }),

    getRequestWithdraw: () => api.get('/v2/treasurer/get/request/withdraw'),

    confirmRequestWithdraw: (requestList) => api.post('/v2/treasurer/confirm/request/list/withdraw', { requestList }),

    downloadRequestWithdraw: (requestList) => api.post('/v2/treasurer/get-file/request/fileDownload', { requestList }),
    deleteFileWithdraw: (fileName) => api.delete(`/v2/treasurer/delete-file/request/fileDelete/${fileName}`),

    confirmRequestWithdrawCancel: (requestList, message, customMessage) =>
        api.post('/v2/treasurer/cancel/request/list/withdraw', { requestList, message, customMessage }),

    // change item info
    changeItemInformation: (itemInfo) => api.post(`/v2/treasurer/item/change/info`, { itemInfo }),
    changeSoldoutItemInfomation: (itemInfo) => api.post(`/v2/treasurer/soldout/item/change/info`, { itemInfo }),

    // add item
    addTreasurerItem: (itemInfo) => api.post(`/v2/treasurer/item/add`, { itemInfo }),
    addTreasurerSoldoutItem: (itemInfo) => api.post(`/v2/treasurer/soldout/item/add`, { itemInfo }),
    // send message
    sendMessageToUser: (selectUser, message, requestDate) => api.post(`/v2/treasurer/send/message`, { selectUser, message, requestDate }),
    sendMessageSalesAnnouncement: (selectId, message) => api.post(`/v2/treasurer/send/sale/announcement`, { selectId, message }),

    // change item status
    confirmChangeItemStatus: (selectItem, status) => api.post(`/v2/treasurer/change/item/status`, { selectItem, status }),
    confirmChangeSoldoutItemStatus: (selectItem, status) => api.post(`/v2/treasurer/change/item/soldout/status`, { selectItem, status }),
    // change item visible
    confirmChangeItemVisible: (selectItem) => api.post(`/v2/treasurer/change/item/visible`, { selectItem }),
    // change item Upcoming
    confirmChangeItemUpcoming: (selectItem) => api.post(`/v2/treasurer/change/item/upcoming`, { selectItem }),
    // treasurer book
    getTreasurerBook: (itemId) => api.get(`/v2/treasurer/book/item/detail/${itemId}`),
    // get treasurer order books
    getTreasurerOrderbooks: () => api.get('/v2/treasurer/orderbooks'),
    // disposal
    getSoldout: () => api.get('/v2/treasurer/get/soldout'),
    getSoldoutItemDetail: (itemId) => api.get(`/v2/treasurer/get/soldout/item/detail/${itemId}`),
    getVote: () => api.get('/v2/treasurer/get/vote'),

    // app push
    getAppUser: () => api.get('/v2/treasurer/get/push'),
    sendAppPush: (selectAppId, ganre, category, head, body, url, image) =>
        api.post('/v2/treasurer/send/push', { selectAppId, ganre, category, head, body, url, image }),

    // distribue piece
    changePieceOwnership: (pieceShareInfo) => api.post('/v2/treasurer/change/ownership', { pieceShareInfo }),

    // club
    // getClub: () => api.get('/v2/treasurer/club'),
    // getClubDetail: (itemId) => api.get(`/v2/treasurer/club/detail/${itemId}`),
    // addClub: (itemInfo) => api.post('/v2/treasurer/club/item/add', { itemInfo }),
    // changeClubInformation: (itemInfo) => api.post(`/v2/treasurer/club/item/change/info`, { itemInfo }),

    // banner
    getBanner: () => api.get('v2/treasurer/get/banner'),
    changeBannerActive: (selectItem) => api.post(`/v2/treasurer/change/banner/active`, { selectItem }),
    getBannerDetail: (itemId) => api.get(`/v2/treasurer/banner/detail/${itemId}`),
    addBanner: (itemInfo) => api.post(`/v2/treasurer/banner/item/add`, { itemInfo }),
    changeBannerInformation: (itemInfo) => api.post(`/v2/treasurer/banner/item/change/info`, { itemInfo }),

    // notice
    getNotice: () => api.get('v2/treasurer/get/notice'),
    changeNoticeActive: (selectItem) => api.post(`/v2/treasurer/change/notice/active`, { selectItem }),
    changeNoticeEmphasis: (selectItem) => api.post(`/v2/treasurer/change/notice/emphasis`, { selectItem }),
    getNoticeDetail: (itemId) => api.get(`/v2/treasurer/notice/detail/${itemId}`),
    addNotice: (itemInfo) => api.post(`/v2/treasurer/notice/item/add`, { itemInfo }),
    changeNoticeInformation: (itemInfo) => api.post(`/v2/treasurer/notice/item/change/info`, { itemInfo })
};
