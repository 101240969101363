import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
// import PopupState, { bindToggle, bindPopper } from 'material-ui-popup-state';
// import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
// import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { setAuth, treasurer } from 'utils/api/treasurer';
import numeral from 'numeral';
// import moment from 'moment';
// import BasicModal from 'ui-component/modals/basicModal';
import AnimateButton from 'ui-component/extended/AnimateButton';
// import { Button, Fab } from '@material-ui/core';
import { Fab } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';

// import { makeStyles, useTheme } from '@material-ui/styles';
import { useTheme } from '@material-ui/styles';

const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
};

const getComparator = (order, orderBy) =>
    order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
};

const headCells = [
    {
        id: 'UserInfo.name',
        numeric: true,
        disablePadding: true,
        label: '아이템 번호'
    },
    {
        id: 'UserInfo.id',
        numeric: false,
        disablePadding: false,
        label: '물품명'
    },
    {
        id: 'piece',
        numeric: true,
        disablePadding: false,
        label: '조각수'
    },
    {
        id: 'agree',
        numeric: true,
        disablePadding: false,
        label: '찬성'
    },
    {
        id: 'odd',
        numeric: true,
        disablePadding: false,
        label: '반대'
    },
    {
        id: 'rating',
        numeric: true,
        disablePadding: false,
        label: '투표율'
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: '상태'
    }
];

const EnhancedTableHead = (props) => {
    // const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts'
                        }}
                    />
                </TableCell> */}
                {headCells.map((headCell) => (
                    <TableCell
                        style={{ overflow: 'scroll', whiteSpace: 'nowrap' }}
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'left'}
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

EnhancedTableHead.propTypes = {
    // numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    // onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired
    // rowCount: PropTypes.number.isRequired
};

const myLogged = localStorage.getItem('adminLogged');

const TreasurerVote = () => {
    const theme = useTheme();
    // const [loading, setLoading] = React.useState(false);
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [dense, setDense] = React.useState(false);
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    // const [changeItemStatus, setChangeItemStatus] = React.useState(false);
    const [treasurerItems, setTreasurerItems] = React.useState([]);
    // const [selectedObject, setSelectedObject] = React.useState([]);
    // const [selectStatus, setSelectStatus] = React.useState('');
    // const [selectItemName, setSelectItemName] = React.useState('');
    // const [itemPrevStatus, setItemPrevStatus] = React.useState('');
    // const [selectItem, setSelectItem] = React.useState(null);
    // const [changeFail, setChangeFail] = React.useState(false);
    // const [changeItemStatusSuccess, setChangeItemStatusSuccess] = React.useState(false);
    // const [changeFailMessage, setChangeFailMessage] = React.useState('');
    // const [anchorEl, setAnchorEl] = React.useState(null);

    // const open = Boolean(anchorEl);
    const navigate = useNavigate();

    // const handleMenuClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };
    // const handleClose = () => {
    //     setAnchorEl(null);
    // };

    const checkAdmin = async () => {
        setAuth(myLogged);
    };

    const getTreasurerItems = async () => {
        try {
            const result = await treasurer.getVote();
            if (result?.data?.result === 'success') {
                setTreasurerItems(result?.data?.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    React.useEffect(() => {
        setAuth(myLogged);
        checkAdmin();
        getTreasurerItems();
    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = treasurerItems.map((n) => n.id);
            // const row = treasurerItems.map((n) => n);
            setSelected(newSelecteds);
            // setSelectedObject(row);
            return;
        }
        setSelected([]);
        // setSelectedObject([]);
    };

    // const handleClick = (event, row) => {
    //     const selectedIndex = selected.indexOf(row.id);
    //     let newSelected = [];
    //     let newSelectedObject = [];

    //     if (selectedIndex === -1) {
    //         newSelected = newSelected.concat(selected, row.id);
    //         newSelectedObject = newSelectedObject.concat(selectedObject, row);
    //     } else if (selectedIndex === 0) {
    //         newSelected = newSelected.concat(selected.slice(1));
    //         newSelectedObject = newSelectedObject.concat(selectedObject.slice(1));
    //     } else if (selectedIndex === selected.length - 1) {
    //         newSelected = newSelected.concat(selected.slice(0, -1));
    //         newSelectedObject = newSelectedObject.concat(selectedObject.slice(0, -1));
    //     } else if (selectedIndex > 0) {
    //         newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    //         newSelectedObject = newSelectedObject.concat(selectedObject.slice(0, selectedIndex), selectedObject.slice(selectedIndex + 1));
    //     }

    //     setSelected(newSelected);
    //     setSelectedObject(newSelectedObject);
    // };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeDense = (event) => {
        setDense(event.target.checked);
    };

    // const handleChange = (menu, item) => {
    //     setSelectStatus(menu?.value);
    //     setSelectItemName(`${`${item?.brand} ${item?.model}`}`);
    //     setItemPrevStatus(item?.status);
    //     setSelectItem(item);
    //     setChangeItemStatus(true);
    // };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // const changeStatus = async () => {
    //     setLoading(true);
    //     setChangeItemStatus(false);
    //     try {
    //         const result = await treasurer.confirmChangeSoldoutItemStatus(selectItem, selectStatus);
    //         if (result?.data?.result === 'success') {
    //             setLoading(false);
    //             setChangeItemStatusSuccess(true);
    //         } else {
    //             setLoading(false);
    //             setChangeFail(true);
    //             setChangeFailMessage(result?.data?.data);
    //         }
    //     } catch (e) {
    //         console.log(e);
    //     } finally {
    //         setLoading(false);
    //     }
    // };

    const addItem = () => {
        navigate('/treasurer/soldout/add/item');
    };

    // Avoid a layout jump when reaching the last page with empty treasurerItems.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - treasurerItems.length) : 0;
    const numSelected = selected.length;

    return (
        <Box sx={{ width: '100%' }}>
            <Paper sx={{ width: '100%', mb: 2, position: 'relative' }}>
                <Toolbar
                    sx={{
                        pl: { sm: 2 },
                        pr: { xs: 1, sm: 1 },
                        ...(numSelected > 0 && {
                            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
                        })
                    }}
                >
                    <Typography sx={{ flex: '1 1 100%', fontSize: 20 }} variant="h6" id="tableTitle" component="div">
                        트레져러 투표현황
                    </Typography>
                    <Tooltip title="Filter list">
                        <IconButton>
                            <FilterListIcon />
                        </IconButton>
                    </Tooltip>
                </Toolbar>
                <TableContainer style={{ overflowX: 'auto' }}>
                    <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={dense ? 'small' : 'medium'}>
                        <EnhancedTableHead
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={treasurerItems.length}
                        />
                        <TableBody>
                            {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 treasurerItems.slice().sort(getComparator(order, orderBy)) */}
                            {stableSort(treasurerItems, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                // .map((row, index) => {
                                .map((row) => {
                                    const isItemSelected = isSelected(row.id);
                                    // const labelId = `enhanced-table-checkbox-${index}`;

                                    const itemStatus =
                                        // eslint-disable-next-line no-nested-ternary
                                        row?.currentStatus === 401
                                            ? '매각 투표중'
                                            : // eslint-disable-next-line no-nested-ternary
                                            row?.currentStatus === 402
                                            ? '매각 진행중'
                                            : // eslint-disable-next-line no-nested-ternary
                                            row?.currentStatus === 403
                                            ? '매각 완료'
                                            : // eslint-disable-next-line no-nested-ternary
                                            row?.currentStatus === 405
                                            ? '투표 시작'
                                            : // eslint-disable-next-line no-nested-ternary
                                            row?.currentStatus === 406
                                            ? '투표 종료'
                                            : // eslint-disable-next-line no-nested-ternary
                                            row?.currentStatus === 407
                                            ? '매각 부결'
                                            : // eslint-disable-next-line no-nested-ternary
                                            row?.currentStatus === 408
                                            ? '매각 부결 완료'
                                            : '';

                                    const voteAllPiece = row?.TreasurerDisposal.recruitmentAmount / row?.TreasurerDisposal.minimumAmount;
                                    const turnout = row?.yes + row?.no;
                                    const voteRate = (turnout / voteAllPiece) * 100;

                                    return (
                                        <TableRow
                                            hover
                                            // role="checkbox"
                                            // aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell align="center">{`${row?.tradeItemId}`}</TableCell>
                                            <TableCell align="left">{`${row?.TreasurerDisposal.brand} ${row?.TreasurerDisposal.model}`}</TableCell>
                                            <TableCell align="center">{numeral(voteAllPiece).format('0,0')}</TableCell>
                                            <TableCell align="center">
                                                {numeral(row?.yes).format('0,0')} ({((row?.yes / turnout) * 100).toFixed(2)} %)
                                            </TableCell>
                                            <TableCell align="center">
                                                {numeral(row?.no).format('0,0')} ({((row?.no / turnout) * 100).toFixed(2)} %)
                                            </TableCell>
                                            <TableCell align="center">
                                                {numeral(turnout).format('0,0')} ({voteRate.toFixed(2)}) %
                                            </TableCell>
                                            <TableCell align="center">{itemStatus}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow
                                    style={{
                                        height: (dense ? 33 : 53) * emptyRows
                                    }}
                                >
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={treasurerItems.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <Fab
                    component="div"
                    onClick={() => addItem()}
                    size="medium"
                    variant="string"
                    color="secondary"
                    sx={{
                        bottom: 0,
                        m: 4,
                        position: 'fixed',
                        right: 20,
                        zIndex: (theme) => theme.zIndex.speedDial,
                        boxShadow: theme.shadows[8]
                    }}
                >
                    <AnimateButton>
                        <IconButton color="inherit" size="large" disableRipple>
                            <AddIcon />
                        </IconButton>
                    </AnimateButton>
                </Fab>
            </Paper>
            <FormControlLabel control={<Switch checked={dense} onChange={handleChangeDense} />} label="Dense padding" />
        </Box>
    );
};

export default TreasurerVote;
