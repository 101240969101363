import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@material-ui/core';

// project imports
import config from 'config';
// import Logo from 'ui-component/Logo';
import TreasurerLogo from '../../../assets/images/logo/treasurer_logo.png';

// ===========================|| MAIN LOGO ||=========================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
        <img style={{ width: 150 }} src={TreasurerLogo} alt="logo" />
    </ButtonBase>
);

export default LogoSection;
